













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Office } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})

export default class CairnsTenancyApplicationForm extends Mixins(View) {
  private formCtx: Context | null = null

  private office: Office | null = null

  mounted() {
    const officeId = '89d8223b-9ff5-4ab3-8ff6-a712d78a05b3'; // Cairns

    API.Offices.Get(officeId).then((office) => {
      this.office = office;
    });

    const ctx: Context = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: 'f0c5495e-8dc3-4cfe-8d45-6efc599b4a14', // Rentals
        },
      ],
    };

    const listingId = this.$route.query.listingId ?? undefined;

    if (listingId) {
      ctx.Items.push({
        Type: ContextItemType.Listing,
        Id: listingId as string,
      });
    }

    this.formCtx = ctx;
  }
}
